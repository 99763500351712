<template>
  <div class="goprofessionDetail">
    <div class="minw" v-loading="loading">
      <div class="top">
        <div style="font-size:21px;margin-bottom: 12px;">
          {{ detailinfo.professionalName }}</div>
        <span>专业代码:&nbsp;{{
          detailinfo.professionalCode
        }}&nbsp;&nbsp;修业年限:&nbsp;{{
  detailinfo.professionalSystem
}}
          &nbsp;&nbsp;授予学位:&nbsp;{{
            detailinfo.professionalDegree
          }}
          &nbsp;&nbsp;</span>
        <el-button style="margin:-10px 0 0 46%"
          @click="$router.push({ path: '/professionalinvestigation', query: { scrollTop: $route.query.scrollTop } })">查询其他专业</el-button>
      </div>
      <div class="bottom">
        <h1 class="title">专业简介</h1>
        <p class="line"></p>
        <span>这个专业是什么</span>
        <p class="detail">{{ detailinfo.professionalInstruction }}</p>
        <span>这个专业干什么</span>
        <p class="detail">{{ detailinfo.professionalDetails }}</p>
        <span>就业去向</span>
        <p class="detail">{{ detailinfo.employmentDestination }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { queryByProfessionalCode } from "@/api/professionalinvestigation.js";
export default {
  name: "goprofessionDetail",
  mixins: [],
  components: {},
  props: {},
  data() {
    return {
      detailinfo: {},
      loading: true
    };
  },
  computed: {},
  watch: {},
  created() {
    this.getDetails(this.$route.query.professionalCode)
  },
  mounted() {
    this.scrollToTop()
  },
  methods: {
    scrollToTop() {

      window.scrollTo({
        top: 0,
        behavior: 'smooth' // 使用平滑滚动效果
      });
    },

    getDetails(professionalCode) {
      const self = this;
      self.loading = true;
      let params = {
        professionalCode,
      }
      queryByProfessionalCode(params).then(res => {
        if (res.code == 200) {
          self.detailinfo = res.data
          self.loading = false;

        }
      })
    }
  },
  beforeRouteLeave(to, from, next) {
    if (to.path === '/professionalinvestigation') {  //
      to.meta.keepAlive = true
      next()
    } else {
      next()
    }
  },

};
</script>

<style scoped lang="scss">
.goprofessionDetail {
  box-sizing: border-box;
  width: 100%;
  // background-color: #fcfbf9;
  padding: 30px 0 80px 0;

  .top,
  .bottom {
    box-sizing: border-box;
    padding: 35px 30px;
    width: 100%;
    border: 1px solid #f4f4f4;
    border-radius: 10px;
    background: white;
  }

  .top {}

  .bottom {
    margin-top: 30px;

    .line {
      margin: 3px;
      height: 3px !important;
      width: 35px;
      background: #30C9B2;
      margin-bottom: 10px;
    }

    .title {
      font-weight: 900;
      font-size: 18px;
    }

    span {
      margin: 20px 0px;
      display: block;
    }

    .detail {
      text-indent: 30px;
    }
  }
}
</style>
